import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 d-flex flex-column mb-6" }
const _hoisted_3 = { class: "form-label" }
const _hoisted_4 = { class: "col-12 d-flex flex-column mb-6" }
const _hoisted_5 = { class: "form-label" }
const _hoisted_6 = { class: "col-12 d-flex flex-column mb-6" }
const _hoisted_7 = { class: "form-label" }
const _hoisted_8 = { class: "col-12 d-flex flex-column mb-6" }
const _hoisted_9 = { class: "form-label" }
const _hoisted_10 = { class: "col-12 d-flex flex-column mb-6" }
const _hoisted_11 = { class: "form-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_FilterBase = _resolveComponent("FilterBase")!
  const _directive_cancelreadonly = _resolveDirective("cancelreadonly")!
  const _directive_loading = _resolveDirective("loading")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_FilterBase, {
    id: "oficina-digital-gerenciar",
    subtitle: "Faça filtros por concessionária",
    showGoToBack: _ctx.showGoToBack,
    hideFilter: _ctx.optionsConcessionaria.length <= 1,
    staticModal: !_ctx.showClose,
    hideClose: !_ctx.showClose,
    disabled: !_ctx.codConcessionariaSelected,
    activeModal: _ctx.activeModal,
    disabledClick: _ctx.disabledClick,
    closeModal: _ctx.closeModal,
    onSearch: _cache[7] || (_cache[7] = ($event: any) => {_ctx.emiteMudanca(); _ctx.closeModal = !_ctx.closeModal})
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.label), 1),
          _withDirectives(_createVNode(_component_el_select, {
            disabled: _ctx.optionsConcessionaria.length <= 1,
            modelValue: _ctx.codConcessionariaSelected,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.codConcessionariaSelected) = $event)),
            filterable: "",
            placeholder: "Buscar por concessionária",
            clearable: false,
            "no-match-text": "Nenhuma concessionária foi encontrada",
            "no-data-text": "Nenhuma concessionária foi encontrada",
            onVisibleChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.disabledClick = !_ctx.disabledClick))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsConcessionaria, (concessionaria, i) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: i,
                  label: concessionaria.text,
                  value: concessionaria.value,
                  disabled: concessionaria.disabled
                }, null, 8, ["label", "value", "disabled"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["disabled", "modelValue"]), [
            [_directive_cancelreadonly],
            [_directive_loading, _ctx.loading]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.labelConsultor), 1),
          _withDirectives(_createVNode(_component_el_select, {
            disabled: _ctx.optionsConcessionaria.length <= 1,
            modelValue: _ctx.consultorSelecionado,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.consultorSelecionado) = $event)),
            filterable: "",
            placeholder: "Buscar por consultor",
            clearable: false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                value: "",
                label: "todos consultores"
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.consultores, (consultor) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: consultor.codConsultor,
                  label: consultor.nome,
                  value: consultor.codConsultor
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["disabled", "modelValue"]), [
            [_directive_cancelreadonly],
            [_directive_loading, _ctx.loading]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.labelStatus), 1),
          _withDirectives(_createVNode(_component_el_select, {
            disabled: _ctx.optionsConcessionaria.length <= 1,
            modelValue: _ctx.statusSelecionado,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.statusSelecionado) = $event)),
            filterable: "",
            placeholder: "Buscar por status",
            clearable: false,
            onVisibleChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.disabledClick = !_ctx.disabledClick))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsStatus, (st, i) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  label: st.label,
                  value: st.value
                }, null, 8, ["label", "value"]))
              }), 256))
            ]),
            _: 1
          }, 8, ["disabled", "modelValue"]), [
            [_directive_cancelreadonly],
            [_directive_loading, _ctx.loading]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.labelPlaca), 1),
          _withDirectives(_createVNode(_component_el_input, {
            disabled: _ctx.optionsConcessionaria.length <= 1,
            modelValue: _ctx.placaSelecionada,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.placaSelecionada) = $event)),
            placeholder: "XXX-XXX",
            clearable: false
          }, null, 8, ["disabled", "modelValue"]), [
            [_directive_cancelreadonly],
            [_directive_loading, _ctx.loading],
            [_directive_maska, { mask: ['AAA-#H##', 'AAA-#H##'], tokens: { 'H': { pattern: /[0-9a-zA-Z]/, uppercase: true } } }]
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.labelNumeroOs), 1),
          _withDirectives(_createVNode(_component_el_input, {
            disabled: _ctx.optionsConcessionaria.length <= 1,
            modelValue: _ctx.numeroOsSelecionado,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.numeroOsSelecionado) = $event)),
            placeholder: "Buscar por número da OS",
            clearable: false
          }, null, 8, ["disabled", "modelValue"]), [
            [_directive_cancelreadonly],
            [_directive_loading, _ctx.loading]
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["showGoToBack", "hideFilter", "staticModal", "hideClose", "disabled", "activeModal", "disabledClick", "closeModal"]))
}