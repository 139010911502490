
import {defineComponent, onMounted, ref, watch ,reactive} from 'vue';
import {ElConfigProvider} from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import useEmitter from '@/composables/Emmiter'
import AuthService from "@/services/AuthService";
import ApiService from '@/services/ApiService';
import {useStore} from 'vuex';
import {Actions} from "@/store/enums/StoreEnums";
import { useRouter } from 'vue-router';
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
import { getOficinaDigitalConsultores } from "@/services/OficinaDigital";
import { GerenciarModel, ConsultorModel } from "@/models/OficinaDigitalModel"

export default defineComponent({
  name: "filter-oficina-digital-gerenciar",
  components: {
    FilterBase,
    ElConfigProvider,
  },

  setup(props, {emit}) {
    const store = useStore();
    const router = useRouter();

    let valorInicialCodConcessionaria;
    if (window.localStorage.getItem("id_usuario")) {
      valorInicialCodConcessionaria = window.localStorage.getItem("filtroConcessionaria") ?? "";
    } else {
      valorInicialCodConcessionaria = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
    }

    const codConcessionariaSelected: any = ref(valorInicialCodConcessionaria ?? "");
    const statusSelecionado = ref<number | null>(null);
    const numeroOsSelecionado: any = ref();
    const placaSelecionada: any = ref();
    const consultorSelecionado = ref();
    const userInfo = AuthService.getUsuarioAuth();
    const token = userInfo.token;
    const emitter = useEmitter();
    const activeModal = ref(false);
    const closeModal = ref(false);
    const label = ref("Selecione uma concessionária");
    const labelStatus = ref("Selecione um status");
    const labelConsultor = ref("Selecione um consultor");
    const labelPlaca = ref("Selecione uma placa");
    const labelNumeroOs = ref("Digite o número da OS");
    const showGoToBack = ref(false);
    const disabledClick = ref(false);
    const codNivel = userInfo.codNivel;
    const lastEmitIsNull = ref(false);
    const helpShow = ref(false);
    const optionsConcessionaria: any = ref([]);
    const loading = ref(false);
    const modalFiltro: any = ref();
    const showClose = ref(false);
    const optionsStatus = ref([
      {
        label: 'todos status',
        value: null
      },
      {
        label: 'novo',
        value: 0
      },
      {
        label: 'criado',
        value: 1
      },
      {
        label: 'analise',
        value: 2
      },
      {
        label: 'finalizado',
        value: 3
      }
    ]);
    // const consultor = ref([]);
    const consultor: any = [];
    const placas: any = [];
    const consultores = ref<ConsultorModel[] | []>([]);
    
    // const arrayFiltro = ref([]);

    onMounted(() => {
      if(store.getters.concessionariasList.length > 0){
        trataConcessionarias();
        setDadosFiltro();
      }
    });

    store.watch(() => store.getters.concessionariasList, () => {
      trataConcessionarias();
      setDadosFiltro();
    }, {deep: true});

    /*Observação dos filtros*/
    
    watch(codConcessionariaSelected, ()=>{
      statusSelecionado.value = null;
      consultorSelecionado.value = '';
      placaSelecionada.value = '';
      numeroOsSelecionado.value = '';
      
      setDadosFiltro();
    });

    /*Inserção de dados no filtro */
    async function  setDadosFiltro() {
      const data = await getOficinaDigitalConsultores(codConcessionariaSelected.value);
      consultores.value = data;
      numeroOsSelecionado.value = '';
      placaSelecionada.value = '';
      statusSelecionado.value = null;
    };

    store.watch(() => store.getters.notificationsSession, async () => {
      consultores.value = [];
      numeroOsSelecionado.value = '';
      placaSelecionada.value = '';
		}, {deep:true});

    async function trataConcessionarias() {
      const concessionarias = store.getters.concessionariasList;
      optionsConcessionaria.value = concessionarias.map(concessionaria => {
        return {
          text: concessionaria.nomeFantasia,
          value: concessionaria.codConcessionaria,
          disabled: !concessionaria.temOficinaDigital,
        }
      }).sort((concessionariaA, concessionariaB) => {
        if (!concessionariaA.disabled && concessionariaB.disabled) {
          return -1;
        }
        if (!concessionariaB.disabled && concessionariaA.disabled) {
          return 1;
        }

        if (concessionariaA.disabled && !concessionariaA.disabled) {
          if (concessionariaA.text < concessionariaB.text){
            return -1;
          }
          if (concessionariaA.text > concessionariaB.text){
            return 1;
          }
          return 0;
        }

        return 0;
      });

      const existeConcessionariaComOficina = optionsConcessionaria.value.some(concessionaria => !concessionaria.disabled);
      
      const cacheConcessionariaSelected = store.getters.concessionariaSelectedInfo;
      if(cacheConcessionariaSelected.codConcessionaria){
        if(cacheConcessionariaSelected.temOficinaDigital){
          codConcessionariaSelected.value = cacheConcessionariaSelected.codConcessionaria;
          // emiteMudanca();
        } else {
          if(!existeConcessionariaComOficina && codNivel == 3){
           	router.push({name: "erroConcessionaria"});
          }
          showGoToBack.value = true;
          label.value = "Selecione uma concessionária com oficina digital";
          codConcessionariaSelected.value = optionsConcessionaria.value[0].disabled ? null : optionsConcessionaria.value[0].value;
          activeModal.value = !activeModal.value;
          showClose.value = false;
        }
      } else {
        if(!existeConcessionariaComOficina && codNivel == 3){
          router.push({name: "erroConcessionaria"});
        }
        
        codConcessionariaSelected.value = optionsConcessionaria.value[0].disabled ? null : optionsConcessionaria.value[0].value;
        activeModal.value = !activeModal.value;
        showClose.value = false;
      }
    };
    
    async function emiteMudanca() { 
      label.value = "Selecione uma concessionária";
      showGoToBack.value = false;
      
      /// valida o token no inicio da ação evitando que se o token esta expirado fazer varias autenticacoes iniciais
      await ApiService.valideTokenTime();
      ///

      if (window.localStorage.getItem("id_usuario")) {
        window.localStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
      } else {
        window.sessionStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
      }

      lastEmitIsNull.value = false;
      store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);
      emitter.emit("filtrar-od-gerenciar", {
        codConcessionaria: codConcessionariaSelected.value, 
        codConsultor: consultorSelecionado.value,
        statusFiltrado: statusSelecionado.value,
        placaFiltrada: placaSelecionada.value,
        numeroOsSelecionado: numeroOsSelecionado.value
      });
    };

    return {
      helpShow,
      activeModal,
      closeModal,
      showClose,
      modalFiltro,
      loading,
      ptBr,
      emitter,
      emiteMudanca,
      lastEmitIsNull,
      optionsConcessionaria,
      codConcessionariaSelected,
      label,
      showGoToBack,
      disabledClick,
      labelStatus,
      labelConsultor,
      labelNumeroOs,
      labelPlaca,
      setDadosFiltro,
      optionsStatus,
      statusSelecionado,
      placaSelecionada,
      numeroOsSelecionado,
      consultorSelecionado,
      consultor,
      placas,
      consultores
    };
  }
});
